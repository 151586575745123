<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="searchform" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <!-- <el-form-item>
          <el-select clearable v-model="search.region" @change="Search" placeholder="区域">
            <el-option
              v-for="item in Regionlist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-input v-model.trim="search.kname" @change="Search" placeholder="邮箱类型名称"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addEmailType">新建</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="记录编码">
        <template v-slot="scope">
          <span>{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="域名">
        <template v-slot="scope">
          <span>{{ scope.row.host }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否ssl">
        <template v-slot="scope">
          <span>{{ scope.row.is_ssl==1?'是':'否' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="端口">
        <template v-slot="scope">
          <span>{{ scope.row.port }}</span>
        </template>
      </el-table-column>
      <el-table-column label="区域">
        <template v-slot="scope">
          <span>{{ scope.row.region }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button size="mini" type="primary" @click="Edittype(scope.row)">修改</el-button>
          <el-button size="mini" type="danger" @click="Deletetype(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog title="编辑邮箱类型" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="记录编号" prop="code" label-width="110px">
          <el-input v-model="form.code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="适合区域" prop="region" label-width="110px">
          <el-select v-model="form.region">
            <el-option
              v-for="item in Regionlist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name" label-width="110px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="域名" prop="host" label-width="110px">
          <el-input v-model="form.host" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="端口号" prop="port" label-width="110px">
          <el-input v-model="form.port" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否使用ssl" prop="is_ssl" label-width="110px">
          <el-radio-group v-model="form.is_ssl">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="Validate(addconfirm)">编辑</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../../axios/pack'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: ''
      },
      list: [],
      total: 0,
      form: {
        code: '',
        region: '',
        name: '',
        host: '',
        port: '',
        is_ssl: 2,
      },
      dialogFormVisible: false,
      rules: {
        code: [{ required: true, message: '请输入记录编号', trigger: 'blur' }],
        region: [{ required: true, message: '请输入适合区域', trigger: 'blur' }],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        host: [{ required: true, message: '请输入域名', trigger: 'blur' }],
        port: [{ required: true, message: '请输入端口号', trigger: 'blur' }],
        is_ssl: [{ required: true, message: '请输入是否使用ssl', trigger: 'blur' }],
      },
      Regionlist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetRegion () {
      api.ReginList({
        page: 1,
        limit: 100
      }).then(res => {
        if (res.data.code == 200) {
          this.Regionlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    addEmailType () {
      this.form = {}
      this.dialogFormVisible = true
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    addconfirm () {
      this.api.EmailTypeUpdate({
        code: this.form.code,
        region: this.form.region,
        name: this.form.name,
        host: this.form.host,
        port: +this.form.port,
        is_ssl: this.form.is_ssl,
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('新增成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error('新值失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search(){
      this.api.EmailTypeList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Edittype (item) {
      this.form = {}
      this.dialogFormVisible = true
      this.api.EmailTypeDetail({
        code: item.code,
        region: item.region
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Deletetype(item){
       this.$confirm('是否删除该邮箱类型?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.EmailTypeDelete({
          code: item.code,
          region: item.region
        }).then(res => {
           if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    }
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () {
      this.GetRegion()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.page {
  float: right;
  margin-top: 10px
}
</style>
